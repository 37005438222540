<template>
  <pc-login-container
    centerTitle="教师端"
    topSecondTitle="教师登录"
    @clickFirstTitle="clickTitle"
  >
    <v-col class="pa-0 ma-0">
      <input-phone
        :width="inputWidth"
        :height="inputHeight"
        :padding="inputPadding"
        :text="phone"
        type="tel"
        :maxLength="11"
        placeholder="手机号"
        @onInput="changePhone"
        icon="icon-phone"
        :error-messages="phoneErrorMsg"
      />

      <input-password
        :width="inputWidth"
        :height="inputHeight"
        :padding="inputPadding"
        :marginTop="inputMarginTop"
        :type="passwordType"
        placeholder="密码"
        @onInput="changePassword"
        :error-messages="passwordErrorMsg"
        :hasPasswordBtn="hasPasswordBtn"
        :isHidePassword="isHidePassword"
        @onPasswordClick="showOrHidePassword"
        :isVerifyPassword="false"
      />

      <submit-btn
        :width="btnWidth"
        :height="btnHeight"
        :marginTop="btnMarginTop"
        :loading="isShowBtnLoading"
        :disabled="!canClickBtn"
        @click="teacherLogin"
        >登录</submit-btn
      >
    </v-col>
  </pc-login-container>
</template>

<script>
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import { teacherLogin } from "@/api/login";
import PcLoginContainer from "@/views/pc/components/pc-login-container";
import InputPhone from "@/components/input-phone";
import InputPassword from "@/components/input-password";
import SubmitBtn from "@/components/submit-btn";
export default {
  name: "pc-login-teacher",
  mixins: [PcMixins],
  props: {},
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      inputWidth: "480px",
      inputHeight: "80px",
      inputPadding: "0px 10px",
      inputMarginTop: "22px",
      btnWidth: "400px",
      btnHeight: "64px",
      btnMarginTop: "30px",

      phone: "", //手机号
      phoneErrorMsg: "", //手机号错误提示
      isPhone: false, //是不是正确的手机号格式
      isPhoneExit: true, //手机号是否已注册

      password: "", //密码
      passwordType: "password", //密码文本格式
      passwordErrorMsg: "", //密码错误提示
      isPassword: false, //是不是正确的密码格式
      hasPasswordBtn: false, //是否有密码显示隐藏按钮
      isHidePassword: true, //是否隐藏密码

      isShowBtnLoading: false, //按钮是否显示Loading
      canClickBtn: false, //能否点击按钮
    };
  },
  components: {
    PcLoginContainer,
    InputPhone,
    InputPassword,
    SubmitBtn,
  },
  computed: {},
  methods: {
    //点击左上角
    clickTitle() {
      this.$router.go(-1);
      // this.$router.replace({
      //   name: "pcLogin",
      // });
    },
    //判断按钮是否符合可以点击的条件
    isBtnCanClick() {
      return this.phone && this.password;
    },
    //监听手机号改变
    changePhone(text) {
      this.phone = text;
      this.canClickBtn = this.isBtnCanClick();
    },
    //监听密码改变
    changePassword(text, isOk) {
      this.password = text;
      this.canClickBtn = this.isBtnCanClick();
      this.hasPasswordBtn = this.password.length > 0;
    },
    //显示或隐藏密码
    showOrHidePassword() {
      this.isHidePassword = !this.isHidePassword;
      if (this.isHidePassword) {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    //教师登录
    teacherLogin() {
      this.isShowBtnLoading = true;
      if (this.canClickBtn) {
        teacherLogin(this.phone, this.password)
          .then((res) => {
            this.isShowBtnLoading = false;
            if (!res) {
              console.log(">>>>>", "教师登录错误");
            } else {
              console.log(">>>>>", "教师登录成功");
              window.localStorage.setItem("jwt", res.data.jwt);
              this.$store.commit("auth/set", res.data.jwt);
              this.$router.replace({
                name: "pcReaderIndex",
              });
            }
          })
          .catch(({ code, msg }) => {
            this.isShowBtnLoading = false;
            console.log(">>>>>", "教师登录失败");
            if (!msg) {
              return;
            }

            this.showSnackbar({ msg });
          });
      } else {
        this.isShowBtnLoading = false;
      }
    },
    //返回像素值
    getSize(size) {
      return Math.round(size) + "px";
    },
  },
  watch: {},
  created() {
    this.screenWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    this.screenHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    let x = this.screenWidth / 1920;
    let y = this.screenHeight / 1080;
    this.inputWidth = this.getSize(480 * x);
    this.inputHeight = this.getSize(80 * y);
    this.inputPadding = "0px" + this.getSize(10 * x);
    this.btnWidth = this.getSize(400 * x);
    this.btnHeight = this.getSize(64 * y);
    this.btnMarginTop = this.getSize(30 * y);
  },
};
</script>

<style lang="scss" scoped></style>
